import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

const SideBar = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName
          logo{
            localFile{
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => (
      <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true; mode: push">
        <div className="uk-offcanvas-bar">
          <button type="button" className="uk-offcanvas-close" data-uk-close > </button>
          <Link to="/" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">{data.strapiGlobal.siteName} </Link>
          <Link to="/news" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Aktualności</Link>
          <Link to="/about" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">O nas</Link>
          <Link to="/horses" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Nasze konie</Link>
          <Link to="/horses2" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Były z nami</Link>
          <Link to="/offer" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Jazda konna</Link>
          <Link to="/hippotherapy" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Hipoterapia</Link>
          <Link to="/sale" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Konie na sprzedaż</Link>
          <Link to="/hotel" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Pensjonat dla koni</Link>
          <Link to="/accomodation" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Noclegi</Link>
          <Link to="/integration" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Atrakcje</Link>
          <Link to="/sessions" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Sesje zdjęciowe</Link>
          <Link to="/pricing" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Cennik</Link>
          <Link to="/gallery" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Galeria</Link>
          <Link to="/contact" onClick="UIkit.offcanvas(element).hide();" className="navbar mobile-links" activeClassName="active">Kontakt</Link>
        </div>
      </div>
    )}
  />
)

export default SideBar