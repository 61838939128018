import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import "../../assets/css/ck-editor.css"
import "../../assets/css/main.css"
import ReactGA from 'react-ga';

const SEO = ({ seo = {} }) => {
  ReactGA.initialize("G-X4FDG59KMD");
  const { strapiGlobal } = useStaticQuery(query)
  const { defaultSeo, siteName, favicon } = strapiGlobal

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription
        }
      )
    }

    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article"
      })
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const metaTags = getMetaTags()

  return (

    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL
        },
        {
          rel: "stylesheet",
          href: withPrefix('fonts.css')
        },
        {
          rel: "stylesheet",
          href: withPrefix('uikit.min.css')
        }
      ]}
      script={[
        {
          src: withPrefix('uikit.min.js')
        },
        {
          src:   withPrefix('uikit-icons.min.js')
        },
        {
          src: withPrefix('jquery.min.js')
        },
        {
          src:withPrefix('uikit.js')
        }
      ]}
      meta={metaTags}
    />
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false
}

const query = graphql`
    query {
        strapiGlobal {
            siteName
            favicon {
                localFile{
                    publicURL
                }
            }
            defaultSeo {
                metaTitle
                metaDescription
            }
        }
    }
`