import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import location_logo from "../../img/location.png"
import Img from "gatsby-image"

const Nav = () => {
  return (
    <StaticQuery
      query={graphql`
      query {
         strapiFooter{
          facebook
        }
        strapiGlobal {
          siteName
          phone
          logo{
            localFile{
                childImageSharp{
                    fixed(width: 400){
                        src
                    }
                }
              }
          }
        }
      }
    `}
      render={(data) => (
        <div className="sticky">
          <nav className="uk-navbar left-padding-tiny" uk-navbar>
            <div className="uk-navbar-left">
              <span><img src={location_logo} width="35px" className="uk-visible@m" alt=""
                         style={{ paddingRight: "10px" }} /> </span>
              <div className="uk-visible@m">
                <span> Rogoźnik 86, 34-471<br /><span>   Ludźmierz woj. małopolskie </span></span>
              </div>
              <div className="uk-navbar-item show-on-tiny">
                <Link to="/">
                  <Img
                    fixed={data.strapiGlobal.logo.localFile.childImageSharp.fixed}
                    imgStyle={{ position: "static" }}
                    className="show-on-tiny"
                    style={{ width: "140px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="uk-navbar-center">
              <div className="uk-navbar-item logo-item">
                <Link to="/">
                  <Img
                    fixed={data.strapiGlobal.logo.localFile.childImageSharp.fixed}
                    imgStyle={{ position: "static" }}
                    className="hide-on-tiny"
                    style={{ width: "180px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="uk-navbar-right">
              <a href={data.strapiFooter.facebook} target="_blank" rel="noreferrer" className="uk-icon-button uk-icon"
                 style={{ marginRight: "10px" }}
                 data-uk-icon="facebook">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1
                      c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z">
                  </path>
                </svg>
              </a>
              <div style={{ marginRight: "5px" }} className="uk-visible@m">
                <span>Instruktor<br /><span> tel. {data.strapiGlobal.phone}</span></span>
              </div>
            </div>
            <a className="uk-navbar-toggle uk-hidden@m uk-icon uk-navbar-toggle-icon" data-uk-toggle=""
               data-uk-navbar-toggle-icon="" href="#offcanvas-flip" aria-expanded="false">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <rect y="9" width="20" height="2"></rect>
                <rect y="3" width="20" height="2"></rect>
                <rect y="15" width="20" height="2"></rect>
              </svg>
            </a>
          </nav>
          <div className="panel_bottom">
            <nav className="uk-navbar subnav">
              <div className="uk-navbar-center links-item-nav">
                <ul className="uk-navbar-nav uk-visible@m">
                  <li><Link to="/" className="navbar" activeClassName="active">{data.strapiGlobal.siteName} </Link></li>
                  <li><Link to="/news" className="navbar" activeClassName="active">Aktualności</Link></li>
                  <li><Link to="/about" className="navbar" activeClassName="active">O nas</Link></li>
                  <li className="hoverLink">
                    <Link to="/horses" className="navbar" activeClassName="active">Nasze konie</Link>
                    <div className="uk-navbar-dropdown dropdown-content">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li><Link to="/horses" className="navbar" activeClassName="active">Nasze konie</Link></li>
                        <li><Link to="/horses2" className="navbar" activeClassName="active">Były z nami</Link></li>
                        <li style={{ height: "25px" }}><Link to="/sale" className="navbar" activeClassName="active">Konie
                          na sprzedaż</Link></li>
                      </ul>
                    </div>
                  </li>
                  <li className="hoverLink">
                    <Link to="/offer" className="navbar" activeClassName="active">Oferta</Link>
                    <div className="uk-navbar-dropdown dropdown-content">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li><Link to="/offer" className="navbar" activeClassName="active">Jazda konna</Link></li>
                        <li><Link to="/hippotherapy" className="navbar" activeClassName="active">Hipoterapia</Link></li>
                        <li><Link to="/integration" className="navbar" activeClassName="active">Atrakcje</Link></li>
                        <li><Link to="/hotel" className="navbar" activeClassName="active">Pensjonat dla koni</Link></li>
                        <li><Link to="/accomodation" className="navbar"  activeClassName="active">Noclegi</Link></li>
                        <li style={{ height: "25px" }}><Link to="/sessions" className="navbar" activeClassName="active">Sesje zdjęciowe</Link></li>
                      </ul>
                    </div>
                  </li>
                  <li><Link to="/pricing" className="navbar" activeClassName="active">Cennik</Link></li>
                  <li><Link to="/gallery" className="navbar" activeClassName="active">Galeria</Link></li>
                  <li><Link to="/contact" className="navbar" activeClassName="active">Kontakt</Link></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

      )}
    />
  )
}

export default Nav