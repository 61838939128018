import CookieConsent from "react-cookie-consent"
import React from "react"

const Cookies = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Akceptuj"
      cookieName="cookiesAccept"
      style={{ background: "#222", textAlign: "center" }}
      buttonStyle={{ color: "#4e503b", fontSize: "8px" }}
      expires={150}
    >
      <p style={{ fontSize: "0.8rem" }}> 🍪 Używamy ciasteczek, aby sprawić byś jeszcze lepiej czuł się na naszej
        stronie. <a href="http://cookiesandyou.com/" target="_blank">Dowiedz się więcej</a></p>
    </CookieConsent>
  )
}
export default Cookies